<template>
    <div>
        <h2>Maintenance Log</h2>
        <div class="col-sm-12">
            <div class="panel panel-default" v-if="isCurrentView('search')">
                <div class="panel-heading"></div>
            <div class="row panel-body">
                <form>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Location</label>
                        <select class="form-control" v-model="cdiLocationID">
                            <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS"
                                    v-bind:key="cdiLocationID" :value="cdiLocationID">{{ cdiLocation }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Month</label>
                        <select class="form-control" v-model="month">
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Year</label>
                        <select class="form-control" v-model="year">
                            <option v-for="[index, year] in yearOptions" v-bind:value="index" v-bind:key="year">{{ year }}</option>
                        </select>
                    </div>
                    <div class='col-md-3 col-sm-4 col-xs-6'>
                      <label>Asset Number</label><input type="text" class="form-control" v-model="assetID">
                    </div>
                    <div class="form-group col-sm-12">
                        <button class="btn btn-primary" type="button" @click="load">Search</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12" v-if="isCurrentView('search')">
        <table class="table table-hover table-responsive">
            <thead>
            <tr>
                <th v-for="(field, id) in state.search.resultFields" v-bind:key="id">{{ field.header }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="log in state.object.data.maintenance_log" v-bind:key="log.maintenance_log_id">
                <td>{{ log.maintenance_log_id }}</td>
                <td>{{ log.employee }}</td>
                <td>{{ log.asset }}</td>
                <td>{{ log.asset_id }}</td>
                <td>{{ log.date }}</td>
                <td>{{ log.reason }}</td>
                <td>{{ log.start_time }}</td>
                <td>{{ log.end_time }}</td>
                <td>{{ log.duration_minutes }}</td>
                <td v-if="!log.maintenance_service_record">
                    <button class="btn btn-default"><span class="glyphicon glyphicon-cloud-upload"
                                                          @click="upload_file(log.maintenance_log_id)"></span>
                    </button>
                </td>
                <td v-else>
                    <ProtectedLink :id="log.maintenance_service_record.cdi_file_id" :linkType="'iconText'"
                                   :text="log.maintenance_service_record.cdi_file_title"
                                   :queryString="queryString" :type="'download'"/>
                    <button class="btn btn-default"
                            @click="$refs.deleteFile.open(log.maintenance_service_record.cdi_file_id)"><span
                            class="glyphicon glyphicon-trash"></span></button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <Confirm ref="deleteFile" :heading="'Disable File?'" :body="'Would you like to disable this file?'"
             @response="deleteFile"/>
    <New v-if="isCurrentView('new')"/>
</div>
</template>
<script>
import {store} from "@/store/BusinessObject.store.js";
import cdiLocationCache from '@/cache/cdiLocation.cache';
import {maintenanceLogStore} from "@/components/tools/MaintenanceLog/MaintenanceLog.store";
import New from "@/components/tools/MaintenanceLog/New";
import ProtectedLink from "@/components/utils/ProtectedLink";
import helpers from "@/store/helpers";
import Confirm from "@/components/utils/Confirm";


export default {
    name: "MaintenanceLog",
    components:
        {
            New,
            ProtectedLink,
            Confirm
        },
    data() {
        return {
            state: store.state,
            maintenanceStoreState: maintenanceLogStore.state,
            cdiLocationID: 1,
            month: (new Date().getMonth() + 1),
            year: new Date().getFullYear(),
            assetID: '',
            cache: {
                cdiLocationCache,
            },
        }
    },
    created() {
        store.initialize(this.maintenanceStoreState);
        this.load()
    },
    methods: {
        isCurrentView: function (view) {
            return view === this.state.currentView;
        },
        load: function () {
            store.apiWithObjectLoad('to_json', {
                'params[cdi_location_id]': this.cdiLocationID,
                'params[month]': this.month,
                'params[year]': this.year,
                'params[asset_id]': this.assetID,})
        },
        upload_file: function (maintenance_log_id) {
            this.$appStore.setFileUploadContext(this.state.name);
            this.$appStore.setFileUploadContextData({
                referenceID: maintenance_log_id,
                data: this.state.object.data
            });
            this.$appStore.state.fileUploadLocation = this.cdiLocationID;
        },
        deleteFile: function (data) {
            let options = {
                'params[cdi_location_id]': this.cdiLocationID,
                'params[cdi_file_id]': data,
            };
            if (options) {
                store.apiGeneric(`maintenance_log/disable_maintenance_service_record/${data}`, options)
                    .then(() => {
                        this.refresh();
                    });
            }
        },
        refresh: function () {
            this.load();
        },
    },
    computed: {
        currentView: function () {
            return this.state.currentView;
        },
        yearOptions: function() {
            const currentYear = new Date().getFullYear();
            const yearArray = [];
            for (let i = 0; i < 7; i++) {
                yearArray.push([currentYear - i, currentYear - i]);
            }
            return yearArray;
        },
        queryString: function () {
            const params = helpers.getQSString({'params': this.companyId});
            return `genid=${Math.floor(Math.random() * 101)}&${params}`;
        }
    },
}
</script>

<style scoped>

</style>